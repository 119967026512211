
import logo from '../images/logo.png';
import './HeaderFooter.css'
import React from 'react'


function Header() {

    return (
      <nav>
      <div className="nav-center">
        {/* <!-- nav header --> */}
        <div className="nav-header">
          
          <a href="/">
          <img src={logo} className="logo" alt="logo" />
          </a>
          <div className="nav-title">
            <a  href="/">
            <h4>EducationVB</h4>
            </a>
          </div>
          
        </div>
        {/* <!-- links --> */}
        <ul className="links">
          <li>
            <a href="/contact">contact</a>
          </li>
        </ul>
        
      </div>
     
    </nav>
    )
}

export default Header;
