import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Header from '../components/header';
import Footer from '../components/footer';
import './Contact.css';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS configuration
    emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', formData, 'YOUR_USER_ID')
      .then((result) => {
        console.log('Email successfully sent!', result.status, result.text);
      }, (error) => {
        console.log('Failed to send email.', error);
      });

    // Reset form after submission
    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
    <div className='contact-us-form'>
      <Header />
      <div className='container'>
        <div>
          <h2>Contact us</h2>
          <p>If you wish to contact us about the website, feel free to use the contact form below. 
            Please note that for job-related queries or submissions the email provided in the job advert should be used.
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input type='text' name='name' value={formData.name} onChange={handleChange} required />
          </label>
          <label>
            Email:
            <input type='email' name='email' value={formData.email} onChange={handleChange} required />
          </label>
          <label>
            Message:
            <textarea name='message' value={formData.message} onChange={handleChange} required />
          </label>
          <button type='submit'>Submit</button>
        </form>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
