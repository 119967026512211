import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import './home.css';

function Home() {
  
  const appItems = [
    { image: '../images/chinesevb.png', title: 'ChineseVB', description: 'Support your Chinese study with ChineseVB, an app providing topic based study with almost no translation. It is an assistant to your regular Chinese classes by letting you review words by topic and use flashcards and quizzes to fill out your vocabulary. You can also read lessons on important grammar points and practice building sentences in Chinese.' },
    { image: '../images/memoriseThis.png', title: 'Memorise This', description: 'Memorise anything with this easy to use little app. Upload prose, scripts, poetry or lists of items and then practice memorising with only first words displayed for sentences and only first letters for lists.' },
    // Add more app items here
  ];

  return (
    <div className="app-home">
      <Header />
      <div className="content-container main-content">
        <div className="introduction">
          <h1>Welcome to EducationVB</h1>
          <p>
            Explore our amazing apps available on iOS and Google Play. 
            Find something that suits your needs, and dive into the experience.
          </p>
        </div>

        <div className="app-grid">
          {appItems.map((app, index) => (
            <div className="app-card" key={index}>
              <img src={app.image} alt={app.title} />
              <h3>{app.title}</h3>
              <p>{app.description}</p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
