import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import privacyPolicy from '../components/privacyPolicy'; // Path to your privacy policy file
import './privacyPolicy.css';

function PrivacyPolicy() {

  return (
    <div className="app-home">
      <Header />
      <div className="content-container main-content">
        <div className="policy-container">
          <h1>Privacy Policy</h1>
          <div className="policy-text">
            {privacyPolicy.split('\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
