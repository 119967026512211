import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// Main Pages
import Home from './screens/home';
// Header / Footer Pages
import Contact from './screens/contact';
import PrivacyPolicy from './screens/privacyPolicy';


function App() {
  
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
      </Routes>
    </Router>
  );
}

export default App;
